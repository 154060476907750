if ("serviceWorker" in navigator) {
    await navigator.serviceWorker.register(
        import.meta.env.MODE === "production"
            ? "/service-worker.js"
            : "/dev-sw.js?dev-sw",
        {type: import.meta.env.MODE === "production" ? "classic" : "module"}
    );

    await Notification.requestPermission()
}
